import {Counter, Dialog, Divider, Flex, Hidden, SnackbarService, String, Switch, withRegistration} from "@hps/hops-react";
import {OrderableTypes} from "@hps/hops-sdk-js";
import {memo, useCallback, useEffect, useMemo, useState} from "react";

import withAuthUser from "Hoc/withAuthUser";
import OrderService from "Services/OrderService";
import StockControlService from "Services/StockControlService";

const RefundItemQtyDialog = memo(({AuthUser, open, onClose, onSubmitted, order, orderItem, Registration}) => {

	const [submitting, setSubmitting] = useState(false);
	const [errorData, setErrorData] = useState(null);
	const [restockItems, setRestockItems] = useState(false);

	const [refundQty, setRefundQty] = useState(0);

	useEffect(() => {

		// Reset the Qty to zero if the dialog is closed/opened
		setRefundQty(0);

	}, [open]);


	/**
	 * Get the order item's object
	 */
	const item = useMemo(() => {
		return order.Items.find(oi => (oi.Uuid === orderItem));
	}, [order, orderItem]);


	/**
	 * Submitting the changes.
	 *
	 * @async
	 * @return {void}
	 */
	// eslint-disable-next-line require-await
	const handleSubmit = useCallback(async () => {

		/**
		 * Submitting!
		 */
		setErrorData(null);
		setSubmitting(true);

		try {

			/**
			 * Make the API call!
			 */

			await OrderService.refundOrderItemQuantity(
				item.Uuid,
				refundQty
			);

			/**
			 * May need to re-stock product items
			 */
			if (restockItems) {

				await StockControlService.restockProduct(
					Registration?.Org?.Id,
					Registration?.Device?.StockLocation,
					item.OrderableId,
					refundQty,
					item.Orderable?.UnitCost?.Average,
					AuthUser?.Id
				)
					.then(() => SnackbarService.snack(`Successfully re-stocked ${refundQty}x ${item.Description}`, "success"))
					.catch(() => SnackbarService.snack(`Unable to re-stock ${refundQty}x ${item.Description}. Please re-stock manually`, "error"));

			}

			/**
			 * We're done!
			 */
			onSubmitted();
			onClose();
			SnackbarService.snack("Updated order item quantity.", "success");

		}
		catch (e) {

			const errorData = e?.response?.data?.Errors;
			setErrorData(errorData);

			SnackbarService.snack(`Item quantity could not be saved.`, "error");

		}

		/**
		 * Done!
		 */
		setSubmitting(false);

	}, [AuthUser, item, onClose, onSubmitted, refundQty, Registration, restockItems]);


	/**
	 * Re-stock Items Toggled
	 */
	const handleToggleRestockItems = () => {
		setRestockItems(!restockItems);
	};


	return (
		<Dialog
			loading={submitting}
			onClose={onClose}
			onOk={handleSubmit}
			okDisabled={refundQty === 0}
			open={open}
			title="Refund Item">

			<String str="Issue a refund by reducing the quantity of an item in this order." />

			<Divider/>

			<Flex
				alignItems="center"
				columnar={true}
				justifyContent="space-between">
				<Flex gap={0}>
					<String
						bold={true}
						str={item?.Description} />
					<String
						color="textSecondary"
						str={`Current Quantity: ${item?.Quantity}`}
						variant="subtitle2" />
					<Hidden hidden={refundQty === 0}>
						<String
							color="error"
							str={`New Quantity: ${item?.Quantity? item.Quantity - refundQty : 0}`}
							variant="subtitle2" />
					</Hidden>
				</Flex>
				<Counter
					label="to refund"
					max={item?.Quantity}
					min={0}
					onChange={setRefundQty}
					value={refundQty} />
			</Flex>

			<Divider/>

			<Hidden hidden={refundQty === 0}>
				<Flex>
					<String
						bold={true}
						color="error"
						str="Changing the quantity of an item will generate an outstanding balance on this order. You must settle the balance on the next screen to complete the refund." />
				</Flex>
			</Hidden>

			<Hidden hidden={
				(item?.Orderable?.Type !== OrderableTypes.Addon) ||
				(item?.Orderable?.StockControlled === false) ||
				(!item.Orderable?.UnitCost?.Average)
			}>
				<Flex
					columnar={true}
					gap={2.5}>
					<Switch
						checked={refundQty === 0 ? false : restockItems}
						disabled={refundQty === 0}
						onClick={handleToggleRestockItems}
						color="primary" />
					<Flex gap={0}>
						<String
							str="Re-stock Items" />
						<String
							color="textSecondary"
							str="Quantity controlled products will be returned to stock at your Stock Location"
							variant="body2" />
					</Flex>
				</Flex>
			</Hidden>

			{errorData}

		</Dialog>
	);

});

export default withAuthUser(withRegistration(RefundItemQtyDialog));

import {faArrowRotateLeft, faRightLeft, faTrashCan} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {Button, Flex, String} from "@hps/hops-react";
import React, {useState} from "react";
import StockRestockDialog from "Stock/Restock/StockRestockDialog";
import StockTransferOutDialog from "Stock/Transfer/StockTransferOutDialog";
import StockWasteDialog from "Stock/Waste/StockWasteDialog";

/**
 * Render a view to handle Stock Inventory display
 */
const StockInventoryView = () => {

	const [wasteDialogOpen, setWasteDialogOpen] = useState(false);
	const [restockDialogOpen, setRestockDialogOpen] = useState(false);
	const [transferOutDialogOpen, setTransferOutDialogOpen] = useState(false);

	const handleWasteDialogOpen = () => {
		setWasteDialogOpen(true);
	};

	const handleRestockDialogOpen = () => {
		setRestockDialogOpen(true);
	};

	const handleTransferOutDialogOpen = () => {
		setTransferOutDialogOpen(true);
	};

	const handleWasteDialogClose = () => {
		setWasteDialogOpen(false);
	};

	const handleRestockDialogClose = () => {
		setRestockDialogOpen(false);
	};

	const handleTransferOutDialogClose = () => {
		setTransferOutDialogOpen(false);
	};

	return (
		<Flex>

			<Flex columnar={true} alignItems="center">
				<Button
					label="Loss/Waste"
					onClick={handleWasteDialogOpen}
					startIcon={<FontAwesomeIcon icon={faTrashCan} />}
					variant="text" />
				<Button
					label="Re-Stock"
					onClick={handleRestockDialogOpen}
					startIcon={<FontAwesomeIcon icon={faArrowRotateLeft} />}
					variant="text" />
				<Button
					label="Transfer"
					onClick={handleTransferOutDialogOpen}
					startIcon={<FontAwesomeIcon icon={faRightLeft} />}
					variant="text" />
			</Flex>

			<String str="Stock inventory is not yet available in POS." />

			<StockWasteDialog open={wasteDialogOpen} onClose={handleWasteDialogClose} />
			<StockRestockDialog open={restockDialogOpen} onClose={handleRestockDialogClose} />
			<StockTransferOutDialog open={transferOutDialogOpen} onClose={handleTransferOutDialogClose} />

		</Flex>
	);

};

export default StockInventoryView;

import {Dialog, Flex, SnackbarService, TextField} from "@hps/hops-react";
import React, {useCallback, useRef, useState} from "react";

import withAuthUser from "Hoc/withAuthUser";
import OrderService from "Services/OrderService";

/**
 * Price input dialog
 * 
 * @package HOPS
 * @subpackage Inventory
 * @copyright Heritage Operations Processing Limited
 */
const AddCustomerCommsDialog = props => {

	const formRefBillingContactDetails = useRef();

	const [loading, setLoading] = useState(false);
	const [commsEntry, setCommsEntry] = useState(null);

	const handleSave = async () => {

		setLoading(true);

		try {
			await OrderService.addCommsLog(
				props.order.Id,
				props.AuthUser.Id,
				commsEntry
			);
		}
		catch (ex) {
			SnackbarService.snack(ex);
		}

		setLoading(false);
		setCommsEntry(null);

		props.onSubmitted?.();
		props.onClose?.();

	};

	const handleChange = useCallback((value, name) => {
		setCommsEntry({...commsEntry, [name]: value});
	}, [commsEntry]);

	return (
		<Dialog
			disabled={loading}
			loading={loading}
			okLabel="Save"
			onOk={handleSave}
			onClose={props.onClose}
			open={props.open}
			title="Customer Comms">
			<form autoComplete="off" ref={formRefBillingContactDetails}>
				<Flex gap={2}>
					<TextField
						autoComplete="new-password" // https://issues.chromium.org/issues/41163264
						disabled={props.disabled}
						label="Remarks"
						maxLength={250}
						multiline={true}
						name="Remarks"
						onChange={handleChange}
						required={true}
						rows={5}
						value={commsEntry?.Remarks} />
					<TextField
						autoComplete="new-password" // https://issues.chromium.org/issues/41163264
						disabled={props.disabled}
						helperText={`Optional. Eg "Following telephone call with customer."`}
						label="Record customer comms"
						maxLength={250}
						name="CustomerComms"
						onChange={handleChange}
						value={commsEntry?.CustomerComms} />
				</Flex>
			</form>
		</Dialog>
	);

};

export default withAuthUser(AddCustomerCommsDialog);

import {Button, Flex, Hidden, Paper, SnackbarService, String} from "@hps/hops-react";
import pluralize from "pluralize";
import {useEffect, useState} from "react";

import withStockControl from "Hoc/withStockControl";

import ExpectedDeliverySource from "./ExpectedDeliverySource";
import StockDeliveryBookInQuantityDialog from "./StockDeliveryBookInQuantityDialog";

const StockDeliveryBookInItem = props => {

	const {
		deliveryKey,
		deliverySource,
		item,
		onUpdateProductDeliveryState,
		StockControl
	} = props;

	const [caseQuantityDialogOpen, setCaseQuantityDialogOpen] = useState(false);
	const [unitQuantityDialogOpen, setUnitQuantityDialogOpen] = useState(false);

	const existingDeliveryStateProduct = StockControl?.Deliveries[deliveryKey]?.Products?.find(p => p.Product === item.Product.Id);

	let color = "error";
	if (existingDeliveryStateProduct?.UnitQuantity > 0) color = "warning";
	if (existingDeliveryStateProduct?.UnitQuantity === (item.ExpectedUnits - item.ReceivedUnits)) color = "success";

	const isExistingQuantityMin = (existingDeliveryStateProduct?.UnitQuantity === 0);
	const isExistingQuantityMax = (existingDeliveryStateProduct?.UnitQuantity === (item.ExpectedUnits - item.ReceivedUnits));
	const isAllStockReceived = (item.ExpectedUnits - item.ReceivedUnits) === 0;


	/**
	 * Hook to add a "zero" quantity if this item doesn't have an existing delivery state
	 */
	useEffect(() => {
		if (!existingDeliveryStateProduct) {
			onUpdateProductDeliveryState(item.Product?.Id, 0, item.UnitCost);
		}
	}, [existingDeliveryStateProduct, item.Product?.Id, item.UnitCost, onUpdateProductDeliveryState]);


	/**
	 * Close the "cases" quantity picker dialog
	 */
	const handleCloseCaseQuantityDialog = () => {
		setCaseQuantityDialogOpen(false);
	};


	/**
	 * Close the "units" quantity picker dialog
	 */
	const handleCloseUnitQuantityDialog = () => {
		setUnitQuantityDialogOpen(false);
	};


	/**
	 * Handle submitting the "cases" quantity picker dialog
	 */
	const handleSubmitCaseQuantity = quantity => {

		const boundQuantity = Math.min((quantity * item.UnitsPerQuantity), (item.ExpectedUnits - item.ReceivedUnits));

		if (boundQuantity < quantity) SnackbarService.snack(`You can only receive a maximum of ${(item.ExpectedUnits - item.ReceivedUnits)} of this item`, "warning");

		onUpdateProductDeliveryState(item.Product?.Id, boundQuantity, item.UnitCost);
		setCaseQuantityDialogOpen(false);
	};


	/**
	 * Handle submitting the "units" quantity picker dialog
	 */
	const handleSubmitUnitQuantity = quantity => {

		const boundQuantity = Math.min(quantity, (item.ExpectedUnits - item.ReceivedUnits));

		if (boundQuantity < quantity) SnackbarService.snack(`You can only receive a maximum of ${(item.ExpectedUnits - item.ReceivedUnits)} of this item`, "warning");

		onUpdateProductDeliveryState(item.Product?.Id, boundQuantity, item.UnitCost);
		setUnitQuantityDialogOpen(false);
	};


	return (
		<Paper>

			<Flex>
				<Flex alignItems="center" columnar={true} justifyContent="space-between">
					<Flex gap={0}>
						<String str={item.Product?.Name} variant="h6" />
						{item.Product?.SKU && <String color="textSecondary" str={`SKU: ${item.Product.SKU}`} variant="body2" />}
						{!(item.Product?.Id) && <String color="textSecondary" str="Not a controlled Stock Item" variant="body2" />}
					</Flex>

					<Flex alignItems="flex-end" gap={0}>
						<String str={`Expecting ${item.ExpectedUnits} units`} variant="h6" />
						{deliverySource === ExpectedDeliverySource.PurchaseOrder && <String color="textSecondary" str={`${item.ExpectedQuantity} ${pluralize("case", item.ExpectedQuantity)}, ${item.UnitsPerQuantity} ${pluralize("unit", item.UnitsPerQuantity)} per case`} variant="body2" />}
						{!!item.ReceivedUnits && <String color="textSecondary" str={`${item.ReceivedUnits} ${pluralize("unit", item.ReceivedUnits)} received previously`} variant="body2" />}
					</Flex>
				</Flex>

				<Hidden hidden={!(item.Product?.Id) || isAllStockReceived}>
					<Flex alignItems="center" columnar={true} justifyContent="space-between">
						<Flex columnar={true} gap={0.5}>
							<Button color="error" label="None" onClick={() => onUpdateProductDeliveryState(item.Product?.Id, 0, item.UnitCost)} variant={isExistingQuantityMin ? "contained" : "outlined"} />
							{deliverySource === ExpectedDeliverySource.PurchaseOrder && <Button color="warning" label="# Cases" onClick={() => setCaseQuantityDialogOpen(true)} />}
							<Button color="warning" label="# Units" onClick={() => setUnitQuantityDialogOpen(true)} />
							<Button color="success" label="Max" onClick={() => onUpdateProductDeliveryState(item.Product?.Id, (item.ExpectedUnits - item.ReceivedUnits), item.UnitCost)} variant={isExistingQuantityMax ? "contained" : "outlined"} />
						</Flex>
						<Flex alignItems="flex-end" gap={0}>
							<String color={color} bold={true} str={`Received ${existingDeliveryStateProduct?.UnitQuantity} ${pluralize("unit", existingDeliveryStateProduct?.UnitQuantity)}`} variant="h6" />
						</Flex>
					</Flex>
				</Hidden>

				<StockDeliveryBookInQuantityDialog
					open={caseQuantityDialogOpen}
					onClose={handleCloseCaseQuantityDialog}
					onSubmit={handleSubmitCaseQuantity}
					title="Enter Number of Cases Received" />

				<StockDeliveryBookInQuantityDialog
					open={unitQuantityDialogOpen}
					onClose={handleCloseUnitQuantityDialog}
					onSubmit={handleSubmitUnitQuantity}
					title="Enter Number of Units Received" />

			</Flex>
		</Paper>
	);

};

export default withStockControl(StockDeliveryBookInItem);

import {Button, Dialog, Flex, SnackbarService, TableList, TextField} from "@hps/hops-react";
import PropTypes from "prop-types";
import {useCallback, useRef, useState} from "react";

import StockControlService from "Services/StockControlService";

const EditBarcodeDialog = props => {

	const {
		editingBarcode,
		onClose,
		product,
		onSuccess,
		open
	} = props;


	const discountCodeInputRef = useRef();

	const [barcodeString, setBarcodeString] = useState(editingBarcode?.Barcode);
	const [isSubmitting, setIsSubmitting] = useState(false);


	/**
	 * Deleting
	 */
	const handleDelete = useCallback(async e => {

		e?.preventDefault?.();

		setIsSubmitting(true);

		const success = await StockControlService.deleteBarcode(
			{
				BarcodeId: editingBarcode?.Id,
				ProductId: product?.Id
			}
		).catch(e => SnackbarService.snack(e));

		setIsSubmitting(false);
		if (success) onSuccess();

	}, [editingBarcode, onSuccess, product]);


	/**
	 * Submitting.
	 */
	const handleSubmit = useCallback(async e => {

		e?.preventDefault?.();

		/** We must have an input! */
		if (!barcodeString) {
			SnackbarService.snack("Please enter a barcode string.", "error");
			return;
		}

		/** Check the validity of the input */
		if (!discountCodeInputRef?.current?.reportValidity?.()) {
			return;
		}

		setIsSubmitting(true);

		let success = null;

		if (editingBarcode) {

			success = await StockControlService.editBarcode(
				{
					BarcodeId: editingBarcode?.Id,
					Barcode: barcodeString,
					ProductId: product?.Id
				}
			).catch(e => SnackbarService.snack(e));

		}
		else {

			success = await StockControlService.addBarcode(
				{
					Barcode: barcodeString,
					ProductId: product?.Id
				}
			).catch(e => SnackbarService.snack(e));

		}

		setIsSubmitting(false);
		if (success) onSuccess();

	}, [barcodeString, editingBarcode, onSuccess, product]);


	const data = [
		{
			label: "Id",
			content: product?.Id
		},
		{
			label: "Name",
			content: product?.Name
		},
		{
			label: "SKU",
			content: product?.SKU ?? "(None)"
		}
	];


	/**
	 * Render!
	 */
	return (
		<Dialog
			actions={<Button color="error" disabled={isSubmitting} label="Delete" onClick={handleDelete} variant="text" />}
			onClose={onClose}
			onOk={handleSubmit}
			loading={isSubmitting}
			open={open}
			title={editingBarcode ? "Edit Barcode" : "Add Barcode"} >
			<form onSubmit={handleSubmit}>

				<Flex>
					<TableList data={data} />
					<TextField
						autoComplete="new-password" // https://issues.chromium.org/issues/41163264
						disabled={isSubmitting}
						helperText="Type or scan barcode."
						inputRef={discountCodeInputRef}
						label="Barcode String"
						maxLength={250}
						name="barcode"
						onChange={setBarcodeString}
						required
						value={barcodeString} />
				</Flex>

			</form>
		</Dialog>
	);

};

EditBarcodeDialog.propTypes = {
	editingBarcode: PropTypes.object,
	disabled: PropTypes.bool,
	onClose: PropTypes.func,
	onSuccess: PropTypes.func,
	open: PropTypes.bool
};

export default EditBarcodeDialog;

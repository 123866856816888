import {Dialog} from "@hps/hops-react";
import PropTypes from "prop-types";

import StockRestockView from "./StockRestockView";

const StockRestockDialog = props => {

	const {
		// disabled,
		onClose,
		// onSuccess,
		open
	} = props;

	/**
	 * Render!
	 */
	return (
		<Dialog
			noCloseButton={true}
			noOkButton={true}
			onClose={onClose}
			open={open}
			title="Re-stock Product"
			width="md">

			<StockRestockView onClose={onClose} />

		</Dialog>
	);

};

StockRestockDialog.propTypes = {
	disabled: PropTypes.bool,
	onClose: PropTypes.func,
	onSuccess: PropTypes.func,
	open: PropTypes.bool
};

export default StockRestockDialog;

import {Dialog} from "@hps/hops-react";
import PropTypes from "prop-types";

import StockTransferOutView from "./StockTransferOutView";

const StockTransferOutDialog = props => {

	const {
		// disabled,
		onClose,
		// onSuccess,
		open
	} = props;

	/**
	 * Render!
	 */
	return (
		<Dialog
			noCloseButton={true}
			noOkButton={true}
			onClose={onClose}
			open={open}
			title="Internal Stock Transfer"
			width="md">

			<StockTransferOutView onClose={onClose} />

		</Dialog>
	);

};

StockTransferOutDialog.propTypes = {
	disabled: PropTypes.bool,
	onClose: PropTypes.func,
	onSuccess: PropTypes.func,
	open: PropTypes.bool
};

export default StockTransferOutDialog;

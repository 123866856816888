import {Button, Dialog, Flex, String, Ul} from "@hps/hops-react";
import {CheckoutBasketItem, ItemClaimWarningCodes} from "@hps/hops-sdk-js";
import React, {useEffect, useState} from "react";

import withBasketClaimData from "Hoc/withBasketClaimData.js";

/**
 * Dialog displaying details of basket claim warnings
 *
 * These occur when the API includes a warning about an item added to a basket.
 *
 * This is a connected component controlled by the "basket claim data" state.
 * 
 * @package HOPS
 * @subpackage Basket
 * @copyright Heritage Operations Processing Limited
 */
const BasketClaimWarningDialog = props => {

	/**
	 * Open?
	 *
	 * This is used because if we accessed the basket claim data
	 * directly to set the open state, we'd logically need to 
	 * clear that data on close, which could affect what we 
	 * render and so cause a flash of changing data as the 
	 * dialog closes. Hence we don't mutate the claim data 
	 * at all and instead synchronise this field with it.
	 *
	 * @type {Boolean}
	 */
	const [open, setOpen] = useState(false);

	useEffect(() => {
		setOpen(!!props.BasketClaimData?.Result?.Warnings?.length);
	}, [props.BasketClaimData]);

	const handleClose = () => setOpen(false);

	const warningCount = props.BasketClaimData?.Result?.Warnings?.length;

	const renderWarnings = () => {
		return (
			<Flex>
				<String str="The items were added to the basket, with warnings:" />
				<Ul>
					{
						props.BasketClaimData?.Result?.Warnings?.map((warning, key) => {

							const item = props.BasketClaimData.Items?.find(i => (i?.Uuid === warning?.Uuid));

							return (
								<li key={key}>
									<String
										bold={true}
										inline={true}
										noFlex={true}
										str={`${CheckoutBasketItem.getUiLabel(item)} ${CheckoutBasketItem.getUiSubtext(item) ? ` (${CheckoutBasketItem.getUiSubtext(item)})` : ""}`} />
									<String
										color="textSecondary"
										noFlex={true}
										str={ItemClaimWarningCodes.getMessage(warning?.Code)} />
								</li>
							);
						})
					}
				</Ul>
			</Flex>
		);
	};

	return (
		<Dialog
			noTransition={true}
			onClose={handleClose}
			noCloseButton={true}
			open={open}
			width="sm">
			<Flex>
				<String
					bold={true}
					centre={true}
					color="warning"
					noFlex={true}
					str="Basket Warning"
					variant="h6" />

				{(((warningCount === 1)) ?
					<Flex>
						<String str="The item was added to the basket, with a warning:" />
						<String color="textSecondary" noFlex={true} str={ItemClaimWarningCodes.getMessage(props.BasketClaimData?.Result?.Warnings?.[0]?.Code)} />
					</Flex> :
					renderWarnings())}
				<Button
					label="OK"
					onClick={handleClose}
					size="large"
					variant="contained" />
			</Flex>
		</Dialog>
	);

};

export default withBasketClaimData(BasketClaimWarningDialog);

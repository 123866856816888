import {UnhandledErrorView} from "@hps/hops-react";
import Box from "@mui/material/Box";
import * as Sentry from "@sentry/react";
import React from "react";
import StockBrowser from "Stock/StockBrowser";

import scss from "./StockView.module.scss";

/**
 * Stock main view
 */
const StockView = () => {

	return (
		<main>
			<Box className={scss.root}>
				<Sentry.ErrorBoundary fallback={UnhandledErrorView}>
					<StockBrowser />
				</Sentry.ErrorBoundary>
			</Box>
		</main>
	);

};

export default StockView;

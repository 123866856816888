import {Flex, String} from "@hps/hops-react";
import React from "react";

import Strings from "./StockNoFeaturesError.strings.json";

/**
 * Render a message if no features are available
 * 
 * @package HOPS
 * @subpackage Stock
 * @copyright Heritage Operations Processing Limited
 */
const StockNoFeaturesError = () => {
	return (
		<Flex>
			<String
				bold={true}
				str={Strings.label}
				variant="h6" />
			<String
				color="textSecondary"
				gap={0.5}
				str={Strings.body} />
		</Flex>
	);
};

export default StockNoFeaturesError;

/* eslint-disable no-unused-vars */
import {Button, Flex, Paper, String} from "@hps/hops-react";
import {Chip} from "@mui/material";
import {makeStyles} from "@mui/styles";
import pluralize from "pluralize";

import ExpectedDeliverySource from "./ExpectedDeliverySource";

const StockDeliveryList = props => {

	const {
		expectedDeliveries,
		onDeliveryClick,
		selectedDelivery
	} = props;

	const useStyles = makeStyles(theme => {
		return (
			{
				paper: {
					color: theme.palette.text.secondary
				},
				selected: {
					backgroundColor: theme.palette.primary.light,
					color: theme.palette.primary.contrastText
				}
			});
	});

	const classes = useStyles();


	const renderDelivery = (delivery, key) => {

		const isSelected = (selectedDelivery?.Source?.Id === delivery?.Source?.Id);

		return (
			<Paper
				classNamePaper={isSelected ? classes.selected : classes.paper}
				key={key}>

				<Flex alignItems="center" justifyContent="space-between" columnar={true}>

					{delivery?.Source?.Type === ExpectedDeliverySource.PurchaseOrder && <Chip color="info" label={`PO${delivery?.Source?.Id}`} />}
					{delivery?.Source?.Type === ExpectedDeliverySource.StockTransfer && <Chip color="warning" label={`TX${delivery?.Source?.Id}`} />}

					<String str={`${delivery?.Items?.length} ${pluralize("item", delivery?.Items?.length)}`} />

					<Button disabled={isSelected} label="View" onClick={() => onDeliveryClick?.(delivery)} variant="contained" />

				</Flex>

			</Paper>
		);
	};


	return (
		<Flex gap={0.25}>
			{expectedDeliveries.map((delivery, key) => renderDelivery(delivery, key))}
		</Flex>
	);

};

export default StockDeliveryList;

import {Dialog, NumericInput} from "@hps/hops-react";
import PropTypes from "prop-types";

const StockDeliveryBookInQuantityDialog = props => {

	const {
		// disabled,
		onClose,
		// onSuccess,
		onSubmit,
		open,
		title
	} = props;


	/**
	 * Render!
	 */
	return (
		<Dialog
			noCloseButton={true}
			noOkButton={true}
			onClose={onClose}
			open={open}
			title={title}>

			<NumericInput
				label="Please enter an amount"
				onCancel={onClose}
				onSubmit={onSubmit} />

		</Dialog>
	);

};

StockDeliveryBookInQuantityDialog.propTypes = {
	disabled: PropTypes.bool,
	onClose: PropTypes.func,
	onSuccess: PropTypes.func,
	open: PropTypes.bool
};

export default StockDeliveryBookInQuantityDialog;

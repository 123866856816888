import {Button, Flex, Loader, NumericInput, SnackbarService, Stepper, String, TableList, withRegistration} from "@hps/hops-react";
import React, {useState} from "react";
import StockControlledProductPicker from "Stock/StockControlledProductPicker";
import StockLocationPicker from "Stock/StockLocationPicker";

import withAuthUser from "Hoc/withAuthUser";
import StockControlService from "Services/StockControlService";

/**
 * Render a view to wasting stock items
 */
const StockWasteView = props => {

	const {
		AuthUser,
		onClose,
		Registration
	} = props;

	const deviceStockLocation = Registration?.Device?.StockLocation;

	const [activeStep, setActiveStep] = useState(0);
	const [isLoading, setIsLoading] = useState(false);

	const [receivingStockLocation, setReceivingStockLocation] = useState(deviceStockLocation);
	const [stockProduct, setStockProduct] = useState(null);
	const [stockUnitQty, setStockUnitQty] = useState(null);

	/**
	 * Define all the steps in this process, and their various properties.
	 */
	const steps = [
		{
			label: "Stock Location",
			backDisabled() {
				return true;
			},
			nextDisabled() {
				return !(receivingStockLocation);
			},
			renderStep() {
				return (
					<Flex>
						<String str="Where will this item be lost/wasted from?" variant="h6" />
						<StockLocationPicker defaultStockLocation={deviceStockLocation} onChange={handleChooseReceivingStockLocation} />
					</Flex>
				);
			}
		},
		{
			label: "Product",
			nextDisabled() {
				return !(stockProduct);
			},
			renderStep() {
				return (
					<Flex>
						<String str="Choose a product, or scan its barcode." variant="h6" />
						<StockControlledProductPicker onChange={handleChooseStockProduct} />
					</Flex>
				);
			}
		},
		{
			label: "Quantity",
			nextDisabled() {
				return true; // Rely on the Numeric Input to move next
			},
			renderStep() {
				return (
					<Flex alignItems="center">
						<String str={`How many '${stockProduct.label}' will be lost/wasted?`} variant="h6" />
						<NumericInput onCancel={handlePreviousStep} label="Quantity" onSubmit={handleChooseQuantity} />
					</Flex>
				);
			}
		},
		{
			label: "Summary",
			nextDisabled() {
				return !(stockUnitQty);
			},
			nextLabel: "Loss/Waste",
			async onNextClick() {

				setIsLoading(true);

				try {

					await StockControlService.wasteProduct(
						Registration?.Org?.Id,
						receivingStockLocation.id,
						stockProduct.id,
						stockUnitQty,
						AuthUser?.Id
					);

					SnackbarService.snack(`Successfully Lost/Wasted ${stockUnitQty}x ${stockProduct.label}`, "success");
					onClose?.();

				}
				catch (e) {
					SnackbarService.snack(e);
				}

				setIsLoading(false);

			},
			renderStep() {

				const wasteSummaryData = [
					{
						label: "Stock Location",
						content: receivingStockLocation?.label
					},
					{
						label: "Product",
						content: stockProduct?.label
					},
					{
						label: "Lose/Waste",
						content: `${stockUnitQty} units`
					}
				];

				return <TableList data={wasteSummaryData} />;

			}
		}
	];


	/**
	 * Choosing the receiving stock location
	 */
	const handleChooseReceivingStockLocation = (e, location) => {
		setReceivingStockLocation(location);
	};


	/**
	 * Choosing the stock product
	 */
	const handleChooseStockProduct = (e, product) => {
		setStockProduct(product);
	};


	/**
	 * Choosing the quantity to waste
	 */
	const handleChooseQuantity = qty => {
		setStockUnitQty(qty);
		handleNextStep();
	};


	/**
	 * Move Stepper UI back one step
	 */
	const handlePreviousStep = () => {
		const prevStep = activeStep - 1;
		setActiveStep(Math.max(prevStep, 0));
	};


	/**
	 * Move Stepper UI forward one step
	 */
	const handleNextStep = () => {
		const nextStep = activeStep + 1;
		setActiveStep(Math.min(nextStep, steps.length));
	};


	return (
		<Flex gap={2}>

			<Stepper
				activeStep={activeStep}
				steps={steps} />

			{steps[activeStep]?.renderStep?.()}

			<Flex columnar={true} justifyContent="space-between">

				<Flex alignItems="center" columnar={true}>
					<Button
						disabled={steps[activeStep]?.backDisabled?.() ?? false}
						label={steps[activeStep]?.backLabel ?? "Back"}
						onClick={handlePreviousStep}
						size="large" />
					<Button
						label="Cancel"
						onClick={onClose}
						size="large"
						variant="text" />
				</Flex>

				<Flex alignItems="center" columnar={true}>
					{isLoading && <Loader size={20} />}
					<Button
						disabled={steps[activeStep]?.nextDisabled?.() ?? false}
						label={steps[activeStep]?.nextLabel ?? "Next"}
						loading={isLoading}
						onClick={steps[activeStep]?.onNextClick ?? handleNextStep}
						size="large"
						variant="contained" />
				</Flex>
			</Flex>

		</Flex>
	);

};

export default withAuthUser(withRegistration(StockWasteView));

import {Autocomplete} from "@hps/hops-react";
import {useMemo} from "react";

import withInventory from "Hoc/withInventory";


/**
 * Picker for choosing stock-controlled products
 */
const StockControlledProductPicker = props => {

	const {
		defaultStockControlledProduct,
		Inventory,
		onChange
	} = props;


	/** Build a list of Stock Controlled items */
	const stockControlledProducts = useMemo(
		() => Inventory.Products
			.filter(p => p.StockControlled === true)
			.sort((productA, productB) => {

				// Sort by Order
				if (productA.Order < productB.Order) return -1;
				if (productA.Order > productB.Order) return 1;

				// Fall through to crude sort by Name
				if (productA.Name.toLowerCase() < productB.Name.toLowerCase()) return -1;
				if (productA.Name.toLowerCase() > productB.Name.toLowerCase()) return 1;

				return 0;

			})
			.map(p => (
				{
					id: p.Id,
					barcodes: p.Barcodes,
					label: p.Name
				}
			)),
		[Inventory.Products]
	);

	return (
		<Autocomplete
			defaultOptionId={defaultStockControlledProduct}
			label="Product"
			onChange={onChange}
			options={stockControlledProducts}
			stringify={option => `${option.barcodes?.toString()} ${option.label}`} />
	);

};

export default withInventory(StockControlledProductPicker);

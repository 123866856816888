/* eslint-disable no-warning-comments */
import {Button, Flex, IconButton, Paper, SnackbarService, String, withRegistration} from "@hps/hops-react";
import {Chip} from "@mui/material";
import {useState} from "react";

import dStockControlDelivery from "Dispatchers/dStockControlDelivery";
import withAuthUser from "Hoc/withAuthUser";
import withStockControl from "Hoc/withStockControl";
import StockControlService from "Services/StockControlService";

import ExpectedDeliverySource from "./ExpectedDeliverySource";
import StockDeliveryBookInItem from "./StockDeliveryBookInItem";

import ProductIcon from "@mui/icons-material/Storefront";

const StockDeliveryBookIn = props => {

	const {
		AuthUser,
		delivery,
		onDeliveryReceived,
		Registration,
		StockControl
	} = props;

	const [loading, setLoading] = useState(false);

	const deliveryKey = `${delivery?.Source?.Type === ExpectedDeliverySource.PurchaseOrder ? "PO" : "TX"}${delivery?.Source?.Id}`;


	/**
	 * Update the local version of the delivery log
	 */
	const handleUpdateProductDeliveryState = (Product, UnitQuantity, UnitCost) => {

		if (!Product) return;

		let existingDeliveryStateProducts = StockControl.Deliveries[deliveryKey]?.Products;

		/** Remove the entry for the current product */
		existingDeliveryStateProducts = existingDeliveryStateProducts?.filter(p => p.Product !== Product) ?? [];

		const deliveryState = {
			Org: Registration?.Org?.Id,
			Source: delivery?.Source.Id,
			DestinationStockLocation: Registration?.Device?.StockLocation,
			Products: [

				/** Existing Products. minus the current product */
				...existingDeliveryStateProducts,

				/** New value for this product */
				{
					Product,
					UnitQuantity,
					UnitCost: (UnitCost ?? 0)
				}
			],
			User: AuthUser?.Id
		};

		/** Update state */
		dStockControlDelivery(deliveryKey, deliveryState);

	};


	/**
	 * Record the amounts entered as a delivery
	 */
	const handleReceiveDelivery = async () => {

		setLoading(true);

		let success = null;

		/** Deliver a Purchase Order */
		if (delivery?.Source?.Type === ExpectedDeliverySource.PurchaseOrder) {

			success = await StockControlService.receiveProducts(StockControl.Deliveries[deliveryKey])
				.catch(error => SnackbarService.snack(error));

		}

		/** Deliver a Transfer In */
		else if (delivery?.Source?.Type === ExpectedDeliverySource.StockTransfer) {

			success = await StockControlService.transferIn(StockControl.Deliveries[deliveryKey])
				.catch(error => SnackbarService.snack(error));

		}


		if (success) {

			setLoading(false);

			SnackbarService.snack(`Success`, "success");

			// Clear our delivery state
			dStockControlDelivery(deliveryKey, null);

			// Signal to parent we're done
			onDeliveryReceived?.();

		}
		else {

			setLoading(false);

		}

	};


	/**
	 * Clear  the local version of the delivery log
	 */
	const handleResetDelivery = () => {
		dStockControlDelivery(deliveryKey, null);
	};


	return (
		<Flex>
			<Paper>
				<Flex alignItems="center" columnar={true}>
					<IconButton icon={ProductIcon} variant="outlined" />
					<String str="Book In Delivery for" variant="h6" />
					{delivery?.Source?.Type === ExpectedDeliverySource.PurchaseOrder && <Chip color="info" label={deliveryKey} />}
					{delivery?.Source?.Type === ExpectedDeliverySource.StockTransfer && <Chip color="warning" label={deliveryKey} />}
				</Flex>
			</Paper>

			{delivery?.Items.map((i, key) => <StockDeliveryBookInItem
				deliveryKey={deliveryKey}
				deliverySource={delivery?.Source?.Type}
				item={i}
				key={key}
				onUpdateProductDeliveryState={handleUpdateProductDeliveryState} />)}

			<Paper>
				{/* <pre>{JSON.stringify(StockControl.Deliveries[deliveryKey], null, 4)}</pre> */}

				<Flex alignItems="center" columnar={true} justifyContent="space-between">
					<Button label="Clear" loading={loading} onClick={handleResetDelivery} variant="text" />
					<Flex columnar={true}>
						{/* <Button label="Scan Loose Items" loading={loading} size="large" /> */}
						<Button label="Receive Delivery" loading={loading} onClick={handleReceiveDelivery} size="large" variant="contained" />
					</Flex>
				</Flex>

			</Paper>
		</Flex>
	);

};

export default withAuthUser(withRegistration(withStockControl(StockDeliveryBookIn)));

import {Button, Divider, Flex, String} from "@hps/hops-react";

import ScanBox from "Components/ScanBox.js";
import withOrders from "Hoc/withOrders.js";
import AppOfflineGate from "Ui/Connectivity/AppOfflineGate.js";

import RecentOrdersView from "./RecentOrders/RecentOrdersView.js";


/**
 * Search view
 * 
 * @package HOPS
 * @subpackage Search
 * @copyright Heritage Operations Processing Limited
 */
const SearchView = () => {
	return (
		<Flex
			gap={2}
			px={1}
			py={1}>
			<String
				bold={true}
				color="primary"
				noFlex={true}
				str="Recall a sale"
				variant="h5" />
			<AppOfflineGate>
				<Flex columnar={true}>
					<ScanBox
						fallbackToSearch={true}
						label="Order No. / QR Code"
						placeholder="L123456"
						showSubmit={true} />
					<Button
						label="Advanced Search"
						uri="/search/advanced" />
				</Flex>
				<String
					color="textSecondary"
					noFlex={true}
					str="Begin by scanning a Barcode, QR Code, or entering an order number above." />
			</AppOfflineGate>
			<Divider />
			<RecentOrdersView />
		</Flex>
	);
};

export default withOrders(SearchView);

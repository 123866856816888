import {Autocomplete, withRegistration} from "@hps/hops-react";
import {useMemo} from "react";

const StockLocationPicker = props => {

	const {
		defaultStockLocation,
		onChange,
		Registration
	} = props;


	/** Build a list of Stock Locations */
	const stockLocations = useMemo(
		() => Registration?.Org?.StockLocations?.map(location => ({id: location.Id, label: location.Name})),
		[Registration?.Org?.StockLocations]
	);


	return (
		<Autocomplete
			defaultOptionId={defaultStockLocation}
			onChange={onChange}
			options={stockLocations} />
	);

};

export default withRegistration(StockLocationPicker);

import {EmptyStateGate, Flex, Navigator, TabPanel, withRegistration} from "@hps/hops-react";
import React from "react";

import dStockTab from "Dispatchers/dStockTab.js";
import withUi from "Hoc/withUi.js";

import StockDeliveryView from "./Delivery/StockDeliveryView.js";
import StockInventoryView from "./Inventory/StockInventoryView.js";
import StockControlledProductPicker from "./StockControlledProductPicker.js";
import StockNoFeaturesError from "./StockNoFeaturesError.js";

import scss from "./StockBrowser.module.scss";


/**
 * Renders all available customer services panels as a tabbed layout
 * 
 * @package HOPS
 * @subpackage Stock
 * @copyright Heritage Operations Processing Limited
 */
const StockBrowser = props => {

	const TabPanelFlexProps = {className: scss.root};

	const tabs = [
		{
			label: "Inventory",
			component: StockInventoryView
		},
		{
			label: "Delivery",
			component: StockDeliveryView
		}
	].filter(t => !t.hidden);

	const activeTab = Math.min(Math.max(props.Ui.StockTab, 0), tabs.length);

	if (!tabs.length) {
		return (
			<Flex px={1} py={1}>
				<EmptyStateGate isEmpty={true} />
			</Flex>
		);
	}


	const handleProductSelected = (e, product) => {
		if (product?.id) Navigator.navigate(`/stock/product/${product.id}`);
	};

	return (
		<TabPanel
			FlexProps={TabPanelFlexProps}
			gap={1}
			noTabsState={StockNoFeaturesError}
			onChange={dStockTab}
			postContent={<div style={{width: "18em"}}><StockControlledProductPicker onChange={handleProductSelected} /></div>}
			tabs={tabs}
			scrollButtons="auto"
			value={activeTab}
			variant="scrollable" />
	);

};

export default withRegistration(withUi(StockBrowser));

import {faBarcode, faPenToSquare} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {Button, Flex, Link, Loadable, Navigator, String, Table, TableList, TabPanel} from "@hps/hops-react";
import {Localisation} from "@hps/hops-sdk-js";
import {Api} from "@hps/hops-sdk-js";
import Box from "@mui/material/Box";
import Chip from "@mui/material/Chip";
import pluralize from "pluralize";
import {useCallback, useEffect, useState} from "react";
import {useParams} from "react-router-dom";
import StockControlledProductPicker from "Stock/StockControlledProductPicker";

import DetailsViewPanel from "Components/DetailsViewPanel";
import withAuthUser from "Hoc/withAuthUser.js";
import StockControlService from "Services/StockControlService.js";

import EditBarcodeDialog from "./EditBarcodeDialog";

import scss from "./ProductDetailsView.module.scss";

const ProductDetailsView = () => {

	/*
	 *const {
	 *	Registration
	 *} = props;
	 */

	const {ProductId} = useParams();

	const [error, setError] = useState(null);
	const [loading, setLoading] = useState(false);
	const [activeProductTab, setActiveProductTab] = useState(0);
	const [productData, setProductData] = useState(null);
	const [editBarcodeDialogOpen, setEditBarcodeDialogOpen] = useState(false);
	const [editBarcodeDialogBarcode, setEditBarcodeDialogBarcode] = useState(null);

	const is404 = (error?.response?.status === 404);

	const handleSearchProductSelected = (e, product) => {
		if (product?.id) Navigator.navigate(`/stock/product/${product.id}`);
	};


	/**
	 * Open Edit Barcode Dialog
	 */
	const handleEditBarcodeDialogOpen = barcode => {
		setEditBarcodeDialogBarcode(barcode);
		setEditBarcodeDialogOpen(true);
	};


	/**
	 * Close Edit Barcode Dialog without taking action
	 */
	const handleEditBarcodeDialogClose = () => {
		setEditBarcodeDialogOpen(false);
	};


	/**
	 * Close Edit Barcode Dialog and refresh our data
	 */
	const handleEditBarcodeDialogSuccess = () => {
		setEditBarcodeDialogOpen(false);
		getProductData();
	};


	/**
	 * Get the order details for a given orderNo
	 *
	 * @async
	 * @return {void}
	 */
	const getProductDetails = useCallback(async productId => {

		setError(null);
		setLoading(true);

		if (productId && parseInt(productId) !== productData?.Id) {
			setProductData(null);
		}

		try {

			await StockControlService.getProduct(productId).then(data => setProductData(data));

		}
		catch (e) {
			setError(e);
		}

		setLoading(false);

	}, [productData]);


	/**
	 * Refresh the product data by Product ID
	 */
	const getProductData = () => {
		if (ProductId) getProductDetails(ProductId, null);
	};


	/**
	 * If there are variables set in the route, retrieve the order on load
	 */
	useEffect(() => {
		getProductData();
	}, [ProductId]);


	/**
	 * Render the "Overview" tab
	 */
	const renderOverviewTab = () => {

		const productGeneralData = [
			{
				label: "ID",
				content: <String str={productData.Id} />
			},
			{
				label: "Name",
				content: <String str={productData.Name} />
			},
			{
				label: "Brand",
				content: <String str={productData.Brand ? productData.Brand : "(None)"} />
			},
			{
				label: "Description",
				content: <>
					<String str={productData.Description ? productData.Description : "(None)"} />
					<Flex columnar={true} alignItems="center" justifyContent="flex-start">
						{productData.Folders.length && <Chip label={`${productData.Folders.length} ${pluralize("folder", productData.Folders.length)}`} />}
						{productData.MinimumAge && <>Minimum Age: <Chip color="error" label={productData.MinimumAge} /></>}
					</Flex>
				</>
			},
			{
				label: "Image",
				content: productData.ImagePath ? <img className={scss.productImage} src={`${Api.baseURL}${productData.ImagePath}`} alt={`${productData.Name}`} /> : <String str="(None)" />
			}
		];

		const productStockControlData = [
			{
				label: "SKU",
				content: <String str={productData.SKU ? productData.SKU : "(None)"} />
			},
			{
				label: "Control Quantity",
				content: <String str={productData.StockControlled ? "Yes" : "No"} />
			},
			{
				label: "Pack Size",
				content: <String str={productData.PackSize} />
			},
			{
				label: "Re-order Warning Level",
				content: <String str={productData.ReorderWarningLevel} />
			}
		];

		const productPriceData = [
			{
				label: "Price",
				content: <String str={Localisation.formatCurrency(productData.PriceExVAT)} />
			},
			{
				label: "VAT Proportion",
				content: <String str={`${productData.VatProportion}%`} />
			},
			{
				label: "VAT Rate",
				content: <String str={`${productData.VatProportion}% at ${productData.VatRate}%`} />
			},
			{
				label: "Advertised Price",
				content: <String bold={true} str={`= ${Localisation.formatCurrency(productData.Price)}`} />
			},
			{
				label: "Cost Centre",
				content: <String str={productData.CostCentre ? productData.CostCentre.FullName : "(None)"} />
			}
		];

		const productCrossSellingData = [
			{
				label: "Membership",
				content: <String str={productData.UpsellOn.Membership ? "Yes" : "No"} />
			},
			{
				label: "Travel",
				content: <String str={productData.UpsellOn.Travel ? "Yes" : "No"} />
			},
			{
				label: "Events",
				content: <>
					<String str={productData.UpsellOn.AllEvents ? "All Events" : productData.UpsellOn.SpecificEvents.length ? "Specific Events:" : "No"} />
					{!(productData.UpsellOn.AllEvents) && productData.UpsellOn.SpecificEvents.map(event => <String str={` - ${event.Name}`} />)}
				</>
			},
			{
				label: "Max Qty",
				content: <String str={productData.MaxQuantity ?? "(Unlimited)"} />
			}
		];

		const productOtherData = [
			{
				label: "Post",
				content: <String str={productData.RequiresAction ? "Post" : "No"} />
			},
			{
				label: "Fulfilment",
				content: <String str={productData.RequiresFulfilment ? `Yes, after ${Localisation.formatDateTime(productData.RequiresFulfilmentAfterTimestamp, Localisation.DateTimeFormats.FullDateTime)}` : "No"} />
			},
			{
				label: "Donation",
				content: <String str={productData.ShowInDonations ? "Yes" : "No"} />
			},
			{
				label: "Age Check",
				content: <String str={productData.MinimumAge ? productData.MinimumAge : "(None)"} />
			},
			{
				label: "Order",
				content: <String str={productData.Order} />
			}
		];

		return (
			<Flex py={1}>
				<DetailsViewPanel title="General">
					<div className={scss.partialTable}>
						<TableList data={productGeneralData} labelsWidth="22rem" />
					</div>
				</DetailsViewPanel>

				<DetailsViewPanel title="Stock Control">
					<div className={scss.partialTable}>
						<TableList data={productStockControlData} labelsWidth="22rem" />
					</div>
				</DetailsViewPanel>

				<DetailsViewPanel title="Price">
					<div className={scss.partialTable}>
						<TableList data={productPriceData} labelsWidth="22rem" />
					</div>
				</DetailsViewPanel>

				<DetailsViewPanel title="Cross-Selling">
					<div className={scss.partialTable}>
						<TableList data={productCrossSellingData} labelsWidth="22rem" />
					</div>
				</DetailsViewPanel>

				<DetailsViewPanel title="Other Settings">
					<div className={scss.partialTable}>
						<TableList data={productOtherData} labelsWidth="22rem" />
					</div>
				</DetailsViewPanel>

				{/*
				<DetailsViewPanel title="Discounts">
					<div className={scss.partialTable}>
						<String str="This product is eligible for the following discounts:" />
					</div>
				</DetailsViewPanel>
				 */}
			</Flex>
		);

	};


	/**
	 * Render the "Barcodes" tab
	 */
	const renderBarcodesTab = () => {

		const tableFields = [
			{
				label: "ID",
				render: b => <String str={b.Id} />
			},
			{
				label: "Barcode String",
				render: b => <Box sx={{fontFamily: "Monospace", fontSize: 18}}>{b.Barcode}</Box>
			},
			{
				label: "Remarks",
				render(b) {
					if (b.SharedWithProducts?.length) {
						return (
							<Flex gap={0}>
								<String color="error" str="Not Unique - other products share this barcode" />
								<Flex gap={2} columnar={true}>
									{b.SharedWithProducts?.map(s => <Link label={s.Name} uri={`/stock/product/${s.Id}`} />)}
								</Flex>
								<String color="textSecondary" str="When scanned, operator will be presented with option to choose either product." variant="body2" />
							</Flex>
						);
					}
					return <></>;
				}
			},
			{
				render: b => <Button
					label="Edit"
					onClick={() => handleEditBarcodeDialogOpen(b)}
					startIcon={<FontAwesomeIcon icon={faPenToSquare}/>} />
			}
		];

		return (
			<Flex py={1}>
				<String str="Barcodes" variant="h6" />

				<Flex columnar={true} alignItems="center">
					<Button
						label="Add Barcode"
						onClick={handleEditBarcodeDialogOpen}
						startIcon={<FontAwesomeIcon icon={faBarcode} />}
						variant="text" />
				</Flex>

				<Table
					items={productData?.Barcodes}
					fields={tableFields} />
			</Flex>
		);
	};


	/**
	 * Render the "Stock Levels" tab
	 */
	const renderStockLevelsTab = () => {

		const data = productData?.StockLevels.map(sl => ({label: sl.Name, content: sl.Quantity}));

		return (
			<Flex py={1}>
				<DetailsViewPanel title="Stock Levels">
					<div className={scss.partialTable}>
						<TableList
							data={data}
							labelsWidth="22rem" />
					</div>
				</DetailsViewPanel>
			</Flex>
		);
	};


	/**
	 * Render the loaded order
	 */
	const renderProduct = () => {


		const tabs = [
			{
				label: "Overview",
				render: renderOverviewTab
			},
			{
				label: "Barcodes",
				render: renderBarcodesTab
			},
			{
				label: "Stock Levels",
				render: renderStockLevelsTab
			}
		].filter(t => !t.hidden);

		return (
			<TabPanel
				gap={0}
				onChange={setActiveProductTab}
				tabs={tabs}
				scrollButtons="auto"
				value={activeProductTab}
				variant="scrollable" />
		);
	};


	return (
		<Flex gap={0}>
			<Flex columnar={true} alignItems="center" justifyContent="space-between">
				<String
					bold={true}
					color="primary"
					noFlex={true}
					str="Stock Item Detail"
					variant="h5" />
				<div style={{width: "18em"}}><StockControlledProductPicker defaultStockControlledProduct={parseInt(ProductId)} onChange={handleSearchProductSelected} /></div>
			</Flex>
			<Loadable
				FlexProps={{alignItems: "flex-start"}}
				error={!!error}
				errorStr={(is404 ? `Unknown product.` : `${error}`)}
				loading={loading}>
				{productData && renderProduct()}
			</Loadable>
			<EditBarcodeDialog
				editingBarcode={editBarcodeDialogBarcode}
				open={editBarcodeDialogOpen}
				onClose={handleEditBarcodeDialogClose}
				onSuccess={handleEditBarcodeDialogSuccess}
				product={productData} />
		</Flex>
	);

};

export default withAuthUser(ProductDetailsView);

import {Api} from "@hps/hops-sdk-js";

/**
 * Device service
 *
 *
 * @package HOPS
 * @subpackage Services
 * @copyright Heritage Operations Processing Limited
 */
class StockControlService {

	/**
	 * Get a list of stock deliveries expected at a location
	 *
	 * @param {Integer|String} locationId ID of the location to search
	 * @return {Promise} Resolves with the API's response data
	 */
	static getExpectedDeliveries(locationId) {
		return Api.call({url: `/api/pos/stock-items/location/${locationId}/deliveries`}).then(({data}) => data);
	}


	/**
	 * Get an product's details by an product ID.
	 *
	 * @param {Integer|String} productId ID of the product to fetch
	 * @return {Promise} Resolves with the API's response data
	 */
	static getProduct(productId) {
		return Api.call({url: `/api/pos/stock-items/item/${productId}`}).then(({data}) => data);
	}


	/**
	 * Send a request to add a barcode to a product
	 *
	 * @return {Promise} Resolves with the API's response data
	 */
	static addBarcode(AddBarcodeRequest) {

		const {Barcode, ProductId} = AddBarcodeRequest;

		return Api.call({
			url: `/api/pos/stock-items/item/${ProductId}/barcodes/add`,
			method: "POST",
			data: {
				Barcode
			}
		}).then(({data}) => data);
	}


	/**
	 * Send a request to delete an existing barcode
	 *
	 * @return {Promise} Resolves with the API's response data
	 */
	static deleteBarcode(DeleteBarcodeRequest) {

		const {BarcodeId, ProductId} = DeleteBarcodeRequest;

		return Api.call({
			url: `/api/pos/stock-items/item/${ProductId}/barcodes/${BarcodeId}`,
			method: "DELETE"
		}).then(({data}) => data);
	}


	/**
	 * Send a request to edit an existing barcode
	 *
	 * @return {Promise} Resolves with the API's response data
	 */
	static editBarcode(EditBarcodeRequest) {

		const {BarcodeId, Barcode, ProductId} = EditBarcodeRequest;

		return Api.call({
			url: `/api/pos/stock-items/item/${ProductId}/barcodes/${BarcodeId}`,
			method: "PUT",
			data: {
				Barcode
			}
		}).then(({data}) => data);
	}


	/**
	 * Send a request to receive an array of products from a supplier
	 *
	 * @return {Promise} Resolves with the API's response data
	 */
	static receiveProducts(ReceiveProductsRequest) {

		const {Org, Source, DestinationStockLocation, Products, User} = ReceiveProductsRequest;

		return Api.call({
			url: "/api/pos/stock-items/inventory/receive",
			method: "POST",
			data: {
				Org,
				Source,
				DestinationStockLocation,
				Products,
				User
			}
		}).then(({data}) => data);
	}


	/**
	 * Send a request to re-stock a product
	 *
	 * @return {Promise} Resolves with the API's response data
	 */
	static restockProduct(Org, StockLocation, Product, UnitQuantity, UnitCost, User) {
		return Api.call({
			url: "/api/pos/stock-items/inventory/restock",
			method: "POST",
			data: {
				Org,
				StockLocation,
				Product,
				UnitQuantity,
				UnitCost,
				User
			}
		}).then(({data}) => data);
	}


	/**
	 * Send a request to receive an array of products from a transfer
	 *
	 * @return {Promise} Resolves with the API's response data
	 */
	static transferIn(ReceiveProductsRequest) {

		const {Org, Source, DestinationStockLocation, Products, User} = ReceiveProductsRequest;

		return Api.call({
			url: "/api/pos/stock-items/inventory/transfer-in",
			method: "POST",
			data: {
				Org,
				Source,
				DestinationStockLocation,
				Products,
				User
			}
		}).then(({data}) => data);
	}


	/**
	 * Send a request to start a transfer out
	 *
	 * @return {Promise} Resolves with the API's response data
	 */
	static transferOut(Org, OriginStockLocation, Product, UnitQuantity, DestinationStockLocation, User) {
		return Api.call({
			url: "/api/pos/stock-items/inventory/transfer-out",
			method: "POST",
			data: {
				Org,
				OriginStockLocation,
				Product,
				UnitQuantity,
				DestinationStockLocation,
				User
			}
		}).then(({data}) => data);
	}


	/**
	 * Send a request to waste a product
	 *
	 * @return {Promise} Resolves with the API's response data
	 */
	static wasteProduct(Org, StockLocation, Product, UnitQuantity, User) {
		return Api.call({
			url: "/api/pos/stock-items/inventory/waste",
			method: "POST",
			data: {
				Org,
				StockLocation,
				Product,
				UnitQuantity,
				User
			}
		}).then(({data}) => data);
	}

}

export default StockControlService;
